<template lang="pug">
.wrap
	VitePwaManifest
	LayoutLanderNav(v-if="settings" :story="settings")
	.page
		slot(keep-alive)
	LayoutSingaFooter.footer(v-if="settings" :story="settings")
</template>

<script setup lang="ts">
const { locale } = useI18n()
// Fetch the settings
const { data: settings } = useNuxtData('settings-' + locale.value)
const { $preview } = useNuxtApp()
const config = useRuntimeConfig()
const token = config.public.storyblokAccessToken
const version = $preview ? 'draft' : 'published'

// Fetch the settings
await useAsyncData(
	'settings-' + locale.value,
	async () => {
		try {
			const defUrl = `https://api.storyblok.com/v2/cdn/stories/settings?&token=${token}&language=${locale.value}&cv=0&resolve_links=url&version=${version}`
			const response: any = await $fetch(defUrl)

			return response.story.content
		} catch (fallbackError) {
			console.error('Settings fetch failed:', fallbackError)
			return {} // Return an empty object or a default structure
		}
	},
	{
		watch: [locale],
		deep: true
	}
)
const setLang = (language: any) => {
	useHead({
		htmlAttrs: {
			lang: language
		}
	})
}

watch(locale, (value) => {
	setLang(value)
})
</script>

<style lang="sass" scoped>
.layout-canvas
	background: linear-gradient(#052E18, black)
	height: 100vh
	width: 100%
	position: absolute
	opacity: 0.1
html
	background-color: black
.wrap
	min-height: 100vh
	display: flex
	flex-direction: column
	background-size: cover
	background-position: center
.page
	flex: 1
	position: relative
	overflow: hidden
.footer
	margin-top: auto
.relative-wrapper
	position: relative
.fixed-bottom
	width: 100%
	position: fixed
	bottom: 0
	left: 0
	z-index: 40
	background: black
</style>
